@if(!isConfirmed) {
    <mat-stepper #stepper
        [orientation]="(isMobile | async) ? 'vertical' : 'horizontal'" 
        [linear]="true">

        <ng-template matStepperIcon="edit">
            <i class="bi bi-pencil"></i>
        </ng-template>

        <mat-step [completed]="false">
            <ng-template matStepLabel>
                <ng-container [ngTemplateOutlet]="firstStepLabelTemplate"></ng-container>
            </ng-template>
            <ng-content></ng-content>
        </mat-step>

        <mat-step [completed]="isSlotSelected()">
            <ng-template matStepLabel>
                <i class="bi bi-calendar3 h1 text-primary"></i>
            </ng-template>
            <mcdo-booking-store-and-slot-select
                [isMobile]="(isMobile | async)"
                [storeTooltipTemplate]="storeTooltipTemplate"
                [eventInfoStore]="eventInfoStore"
                (onSlotSelect)="onSlotSelect($event)"
                [stores]="stores"
                [criterions]="booking?.getCriterions()"
                >
            </mcdo-booking-store-and-slot-select>
        </mat-step>

        <mat-step [completed]="hasContact()">
            <ng-template matStepLabel>
                <i class="bi bi-envelope-fill h1 text-primary"></i>
            </ng-template>
            <mcdo-booking-contact-info 
                (onContactSubmit)="onContactUpdate($event)">
            </mcdo-booking-contact-info>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>
                <i class="bi bi-check-circle-fill h1 text-primary"></i>
            </ng-template>
            <ng-template matStepContent>
                <mcdo-booking-validate
                    [legalConditions]="legalConditions"
                    [eventRecapTemplate]="eventRecapTemplate"
                    (onConfirm)="onConfirm()">
                </mcdo-booking-validate>
            </ng-template>
        </mat-step>
    </mat-stepper>
} @else {
    <mcdo-booking-confirmation [image]="confirmationImage"></mcdo-booking-confirmation>
}


<ng-template #storeTooltipTemplate let-params>
    <h5 class="bold">{{ params.store.publicName }}</h5>
    <div>
        <i class="fa fa-map-marker"></i>
        {{
        params.store.address.line1 +
        ' ' +
        params.store.address.line2 +
        ' ' +
        params.store.address.line3 +
        ' ' +
        params.store.address.city +
        ' ' +
        params.store.address.postalZip
        }}
    </div>
    <ng-container [ngTemplateOutlet]="storeTooltipExtendedTemplate" [ngTemplateOutletContext]="{ $implicit: params }"></ng-container>
</ng-template>
