import { AlertSettings } from "./alertSettings";
import { TimeSlot } from "./time-slot";

export abstract class StoreBookingSettings {
    id: number;
    storeId: number;
    languages: string;
    durationPartyMinutes: number;
    bookingBeforeMinDays: number;
    bookingBeforeMaxMonths: number;
    alerts: AlertSettings;
    managerMail: string;

    constructor(storeIdentifier: number) {
        this.storeId = storeIdentifier;
        this.languages = '';
        this.durationPartyMinutes = 0;
        this.bookingBeforeMinDays = 0;
        this.bookingBeforeMaxMonths = 0;
        this.alerts = new AlertSettings();
    }
}
