import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../config/api-config';
import { PriceSettings } from '../data/price-settings';
import { StoreBookingSettings } from '../data/storeBookingSettings';
import { RestService } from './rest-service';

@Injectable({
  providedIn: 'root'
})
export class StoreSettingsService<T extends StoreBookingSettings> extends RestService {

  constructor(
    protected httpClient: HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.bookingService);
  }

  // Crée les paramètres d'une boutique
  public createSettings(settings: T): Observable<T> {
    return this.httpClient.post<T>(this.api('settings/store'), settings);
  }

  // Met à jour les paramètres d'une boutique
  public updateSettings(storeIdentifier: number, settings: T): Observable<T> {
    return this.httpClient.put<T>(this.api(`settings/store/${storeIdentifier}`), settings);
  }

  // Récupère les paramètres d'une boutique par identifiant
  public getSettings(storeId: number): Observable<T> {
    const params = new HttpParams().set('storeIdentifier', storeId.toString());
    return this.httpClient.get<T>(this.api('settings/store/search'), { params });
  }

  public getThemeSettings(storeSettingsId: number): Observable<any> {
    const params = new HttpParams().set('storeSettingsId', storeSettingsId.toString());
    return this.httpClient.get<any>(this.api('settings/store/themes'), { params });
  }


  // Met à jour les paramètres spécifiques de thème pour un magasin
  public updateThemeSettings(storeSettingsId: number, themeSettings: any): Observable<any> {
    const params = new HttpParams().set('storeSettingsId', storeSettingsId.toString());
    return this.httpClient.put<any>(this.api('settings/store/themes'), themeSettings, { params });
  }

  public getGlobalPriceSettings(): Observable<PriceSettings> {
    return this.httpClient.get<PriceSettings>(this.baseUrl + 'pricesettings');
  }

}
