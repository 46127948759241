import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assign } from 'lodash-es';
import { map } from 'rxjs';
import { ApiConfig } from '../config/api-config';
import { AvailableSlot } from '../data/availableSlot';
import { RestService } from './rest-service';

@Injectable({
  providedIn: 'root'
})
export class AvailableSlotService<T extends AvailableSlot> extends RestService {

  constructor(
    protected httpClient: HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.bookingService);
  }

  public getSlotsForStoreAndDateBetween(storeIdNumber: number, begin: Date, end: Date) {
    const params = new HttpParams()
      .set('storeIds', storeIdNumber)
      .set('date', begin.toISOString().split('T')[0])
      .set('endDate', end.toISOString().split('T')[0]);

    return this.getSlots(params);
  }

  public getSlotsForStoreAndDate(storeIds: number[], date: Date) {
    const params = new HttpParams()
      .set('storeIds', storeIds.join(','))
      .set('date', date.toISOString().split("T")[0]);

    return this.getSlots(params);
  }

  getSlotsForStoreAndDateWithCriteria(storeIds: number[], date: Date, criterions: Map<string, any>) {
    let params = new HttpParams()
    .set('storeIds', storeIds.join(','))
    .set('date', date.toISOString().split("T")[0]);

    for(const criteria of criterions) {
      params = params.set(criteria[0], criteria[1]);
    }

    return this.getSlots(params);
  }

  private getSlots(params : HttpParams) {
    return this.httpClient
      .get<T[]>(this.api('slots'), { params })
      .pipe(
        map(x => {
          const result: T[] = [];
          for (const iterator of x) {
            result.push(
              assign(new AvailableSlot(), iterator) as T
            )
          }
          return result;
        })
      );
  }
}
