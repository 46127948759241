import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, mergeMap } from 'rxjs';

@Directive({
  selector: '[isAuthenticated]',
  standalone: true
})
export class IsAuthenticatedDirective implements OnInit {

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private publicEventService: PublicEventsService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>
  ) { }

  ngOnInit(): void {
    this.publicEventService.registerForEvents()
      .pipe(
        filter(evt => evt.type === EventTypes.CheckingAuthFinished),
        mergeMap(x => this.oidcSecurityService.isAuthenticated())
      )
      .subscribe(x => this.updateView(x));
  }

  updateView(isAuthenticated : boolean) {
    if (isAuthenticated) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }

}
