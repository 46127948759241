import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { map, Observable } from 'rxjs';
import { ApiConfig } from '../config/api-config';
import { Booking } from '../data/booking';
import { BookingType } from '../data/booking-type';
import { toLocaleDateTimeString } from '../utils/toLocaleDateTimeString';
import { RestService } from './rest-service';

@Injectable({ providedIn: 'root' })
export class BookingService<T extends Booking> extends RestService {
  constructor(
    protected httpClient: HttpClient,
    apiConfig: ApiConfig
  ) {
    super(apiConfig.bookingService);
  }

  private getBookings(params: HttpParams) : Observable<T[]> {  
    return this.httpClient.get<T[]>(this.api('bookings'), { params })
      .pipe(map(x => x.map(y => assign(new Booking(), y) as T)));
  }

  public searchBetweenDate(type : BookingType, dateStart: Date, dateEnd: Date, ...storeIds: number[]) : Observable<T[]> {
    return this.getBookings(
      new HttpParams()
        .set('type', type)
        .set('storeIds', storeIds.join())
        .set('beginDate', dateStart.toISOString().split("T")[0])
        .set('endDate', dateEnd.toISOString().split("T")[0])
    )
  }

  public searchBetweenBookingDate(type : BookingType, dateStart: Date, dateEnd: Date, ...storeIds: number[]) : Observable<T[]> {
    return this.getBookings(
      new HttpParams()
        .set('type', BookingType[type])
        .set('storeIds', storeIds.join())
        .set('bookingBeginDate', dateStart.toISOString().split("T")[0])
        .set('bookingEndDate', dateEnd.toISOString().split("T")[0])
    )
  }

  public getBooking(publicId: string) {
    return this.httpClient.get<T>(this.api(`bookings/${publicId}`))
      .pipe(map(y => assign(new Booking(), y) as T));
  }

  public updateBooking(booking: T) {
    return this.httpClient.put(
      this.api(`bookings/${booking.publicId}`),
      booking
    );
  }

  save(event: T) {
    if (!event.bookingDate)
      event.bookingDate = new Date();

    const tmpEvent = cloneDeep(event) as any;
    tmpEvent.date = toLocaleDateTimeString(event.date);
    tmpEvent.bookingDate = toLocaleDateTimeString(event.bookingDate);
    return this.httpClient.post<T>(this.api('bookings'), tmpEvent);
  }

}
