import { BookingType } from "./booking-type";
import { Contact } from "./contact";

export class Booking {
  id?: number;
  type: BookingType;
  storeIdentifier: number;
  date: Date;
  bookingDate: Date;
  duration: number;
  language: string;
  contact: Contact;
  creationRole: string;
  comment: string;
  publicId: string;
  cancellationSource: string;
  cancelled: boolean;

  get endDate() {
    const endDate = new Date(this.date);
    endDate.setMinutes(this.date.getMinutes() + this.duration);
    return endDate;
  }

  getCriterions() : Map<string, any> {
    return new Map();
  }
}