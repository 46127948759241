import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { AvailableSlot } from '../../../../data/availableSlot';
import { Store } from '../../../../data/store';
import { AvailableSlotService } from '../../../../services/available-slot.service';
import { InputDatepickerComponent } from "../../../input-datepicker/input-datepicker.component";
import { MapsComponent } from "../../../maps/maps.component";

@Component({
  selector: 'mcdo-booking-store-and-slot-select',
  standalone: true,
  imports: [InputDatepickerComponent, MapsComponent, CommonModule, FormsModule, ModalModule, TranslateModule, NgxSpinnerModule],
  templateUrl: './store-and-slot-select.component.html',
  styleUrl: './store-and-slot-select.component.css'
})
export class StoreAndSlotSelectComponent<T extends AvailableSlot> {

  private _currentStore: Store;

  get currentStore() {
    return this._currentStore;
  }

  set currentStore(val: Store) {
    this._currentStore = val;
    this.currentSlot = null;
  }

  currentSlot: T;

  @Input()
  isMobile: boolean;

  @Input({ required: true })
  stores: Store[];

  @Input({ required: true })
  storeTooltipTemplate: TemplateRef<any>;

  @Input()
  eventInfoStore: TemplateRef<any>;

  @Input()
  criterions: Map<string, any>;

  @Output()
  onSlotSelect = new EventEmitter<T>();

  slots: T[] = [];

  get storeSlots() {
    return this.slots.filter(x => x.storeId === this.currentStore.storeIdNumber);
  }

  dateSelected: Date;

  @ViewChild("mapAndSlotModal")
  modalMapAndSlots?: TemplateRef<any>;

  mapOptions: google.maps.MapOptions = {
    center: {
      lat: 46.826510,
      lng: 8.1
    },
    zoom: 8,
    clickableIcons: false,
    streetViewControl: false
  };

  constructor(
    private availableSlotSvc: AvailableSlotService<T>,
    private modalSvc: NgbModal,
    private spinnerSvc: NgxSpinnerService
  ) { }

  openMapAndSlotModal() {
    if (this.modalMapAndSlots) {
      this.modalSvc.open(this.modalMapAndSlots, { fullscreen: true });
    }
  }

  onDateChange(date: Date) {
    if (!date) return;
    this.currentSlot = null;
    this.currentStore = null;
    this.spinnerSvc.show();

    const storeIds = this.stores.map(x => x.storeIdNumber);

    this.availableSlotSvc.getSlotsForStoreAndDateWithCriteria(storeIds, date, this.criterions)
      .subscribe(slots => {
        this.slots = slots;
        this.spinnerSvc.hide();

        // update store on map
        const updatedStores: Store[] = [];
        for (const store of this.stores) {
          store.matchesCriteria = this.slots.some(x => x.storeId === store.storeIdNumber);
          updatedStores.push(store);
        }
        this.stores = updatedStores;
        this.spinnerSvc.hide();
      });
  }

  getStoreTooltipContext(store: Store): any {
    return {
      store: store,
      slots: this.slots.find(x => x.storeId === store.storeIdNumber)
    }
  }

  onSlotSelected() {
    this.onSlotSelect.emit(this.currentSlot);
  }
}
