import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs';
import { AvailableSlot } from '../../../data/availableSlot';
import { Booking } from '../../../data/booking';
import { Contact } from '../../../data/contact';
import { Store } from '../../../data/store';
import { BookingService } from '../../../services/booking.service';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ContactInfoComponent } from "./contact-info/contact-info.component";
import { StoreAndSlotSelectComponent } from "./store-and-slot-select/store-and-slot-select.component";
import { LegalCondition } from './validate/legal-condition';
import { ValidateComponent } from "./validate/validate.component";


@Component({
  selector: 'mcdo-booking-form',
  standalone: true,
  imports: [
    MatStepperModule, AsyncPipe, StoreAndSlotSelectComponent, ContactInfoComponent, ValidateComponent, CommonModule,
    TranslateModule, ConfirmationComponent
  ],
  templateUrl: './booking-form.component.html',
  styleUrl: './booking-form.component.css'
})
export class McdoBookingFormComponent<T extends Booking, TSlot extends AvailableSlot> {

  booking: T;
  isConfirmed: boolean;

  @Input({required: true})
  set bookingData(val: T) {
    if (val != null) {
      this.booking = val;
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  @Input({ required: true })
  stores: Store[];

  @Input({ required: true })
  firstStepLabelTemplate: TemplateRef<void>;

  @Input()
  storeTooltipExtendedTemplate: TemplateRef<any>;

  @Input()
  eventRecapTemplate: TemplateRef<void>;

  @Input({required: true})
  legalConditions: LegalCondition[];

  @Input()
  eventInfoStore: TemplateRef<any>;
  
  @Input({required: true})
  confirmationImage: string;

  @ViewChild('stepper')
  private stepper: MatStepper;

  isMobile = this.breakpointObserver
    .observe('(max-width: 577px)')
    .pipe(map(breakPoint => breakPoint.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private bookingSvc: BookingService<T>,
    private spinnerSvc: NgxSpinnerService
  ) { }

  onSlotSelect(slot: TSlot) {
    this.booking.date = slot.dateTime;
    this.booking.duration = slot.duration;
    this.booking.storeIdentifier = slot.storeId;
    this.stepper.selected.completed = this.isSlotSelected();
    this.stepper.next();
  }

  isSlotSelected() : boolean {
    return Boolean(this.booking?.storeIdentifier && this.booking?.date && this.booking?.duration);
  }

  onContactUpdate(contact: Contact) {
    this.booking.contact = contact;
    this.stepper.selected.completed = this.hasContact();
    this.stepper.next();
  }

  hasContact() : boolean {
    return Boolean(this.booking?.contact);
  }
  
  onConfirm() {
    this.spinnerSvc.show();
    this.bookingSvc.save(this.booking).subscribe(() => {
      this.isConfirmed = true;
      this.spinnerSvc.hide();
    });
  }
}
