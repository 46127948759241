export class AvailableSlot {
    public storeId: number;
    public dateTime: Date;
    public duration: number;
    public themeId: number;

    public get timeSlotsEnd() {
        const endDate = new Date(this.dateTime);
        endDate.setMinutes(this.dateTime.getMinutes() + this.duration);
        return endDate;
    }
}