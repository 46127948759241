import { Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { CalendarOptions, DatesSetArg, EventClickArg, EventInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import deLocale from '@fullcalendar/core/locales/de';
import frLocale from '@fullcalendar/core/locales/fr';
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { AvailableSlot } from '../../../data/availableSlot';
import { Booking } from '../../../data/booking';
import { BookingChannel } from '../../../data/booking-channel';
import { BookingType } from '../../../data/booking-type';
import { Store } from '../../../data/store';
import { AvailableSlotService } from '../../../services/available-slot.service';
import { BookingService } from '../../../services/booking.service';
import { StoreService } from '../../../services/store.service';
import { RoleUtils } from '../../../utils/roleUtils';
import { StoreSelectComponent } from "../../store-select/store-select.component";
import { EventDetailsActions, EventDetailsModalComponent, EventDetailsOptions } from './event-details-modal/event-details-modal.component';

@Component({
  selector: 'mcdo-booking-calendar',
  standalone: true,
  imports: [TranslateModule, NgSelectModule, FullCalendarModule, StoreSelectComponent],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css',
})
export class BookingCalendarComponent<TEvent extends Booking> implements OnInit {

  @Output()
  availableSlotSelected = new EventEmitter<AvailableSlot>();

  @Output()
  eventToEditSelected = new EventEmitter<TEvent>();

  @Input({ required: true })
  eventTitleBuilder: (booking: TEvent) => string;

  @Input({required: true})
  bookingDetail: TemplateRef<any>;

  window = window;

  stores: Store[];
  currentStore: Store;
  dateRange: DatesSetArg;
  events: EventInput[];

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, bootstrap5Plugin, timeGridPlugin],
    locales: [frLocale, deLocale, itLocale],
    locale: this.translate.currentLang,
    height: "auto",
    themeSystem: "bootstrap5",
    fixedWeekCount: false,
    allDaySlot: false,
    slotMinTime: "08:00:00",
    slotMaxTime: "21:00:00",
    displayEventEnd: true,
    headerToolbar: {
      left: 'prev,today,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    datesSet: this.onDateRangeChanged.bind(this),
    eventClick: this.onEventClick.bind(this),
    eventClassNames: (arg) => {
      const booking = arg.event.extendedProps["value"] as TEvent;
      if (booking.cancelled) {
        return ["calendar-event-cancelled"];
      }

      return [];
    }
  };

  availableSlotTitle: string;

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private bookingService: BookingService<TEvent>,
    private availableSlotService: AvailableSlotService<AvailableSlot>,
    private storeService: StoreService,
    private spinnerSvc: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(langEvent => {
      this.calendarOptions.locale = langEvent.lang;
    });

    this.translate.stream("calendar.availableSlot").subscribe(availableSlotTitle => this.availableSlotTitle = availableSlotTitle)
    this.storeService.getAll().subscribe(stores => this.stores = stores);
  }

  onStoreChange(store: Store) {
    this.currentStore = store;
    this.setEvents();
  }

  private onDateRangeChanged(arg: DatesSetArg): void {
    this.dateRange = arg;
    this.setEvents();
  }

  private onEventClick(arg: EventClickArg) {
    const type = arg.event.extendedProps["type"];
    const val = arg.event.extendedProps["value"];

    switch (type) {
      case Booking.name:
        this.showBookingDetailsModal(val, arg.event);
        break;

      case AvailableSlot.name:
        this.availableSlotSelected.emit(val);
        break;
    }
  }

  private showBookingDetailsModal(booking: TEvent, event: EventImpl) {
    const option: EventDetailsOptions<TEvent> = {
      event: event,
      booking: booking,
      store: this.currentStore,
      bookingDetail: this.bookingDetail
    }

    this.modalService.open(EventDetailsModalComponent, {
      injector: Injector.create({
        providers: [
          {
            provide: EventDetailsOptions,
            useValue: option
          }
        ]
      })
    }).result.then((result: EventDetailsActions) => {
      switch (result) {
        case EventDetailsActions.Edit:
          this.eventToEditSelected.emit(booking);
          return;

        case EventDetailsActions.Cancel:
          booking.cancelled = true;
          booking.cancellationSource = "booking-form.step4.label.removalSource.gui";
          this.bookingService.updateBooking(booking).subscribe(() => this.setEvents());
          return;
      }
    });

  }

  private setEvents() {
    if (!(this.currentStore && this.dateRange))
      return;

    this.events = [];

    this.spinnerSvc.show();
    forkJoin({
      availableSlots: this.availableSlotService.getSlotsForStoreAndDateBetween(this.currentStore.storeIdNumber, this.dateRange.start, this.dateRange.end),
      bookings: this.bookingService.searchBetweenDate(BookingType.BIRTHDAY, this.dateRange.start, this.dateRange.end, this.currentStore.storeIdNumber)
    }).subscribe(response => {
      const events = [];
      for (const booking of response.bookings) {
        events.push(
          this.createEventFromBooking(booking as TEvent)
        );
      }

      for (const event of this.createEventFromAvailableBookingSlot(response.availableSlots)) {
        events.push(event);
      }

      this.events = events;
      this.spinnerSvc.hide();
    });
  }

  private createEventFromBooking(booking: TEvent): EventInput {
    return {
      id: booking.id.toString(),
      start: booking.date,
      end: booking.endDate,
      title: this.eventTitleBuilder(booking),
      color: this.getEventColor(booking.creationRole),
      interactive: true,
      extendedProps: {
        type: Booking.name,
        value: booking,
      }
    };
  }

  private getEventColor(role: string): string {
    switch (RoleUtils.roleToBookingChannel(role)) {
      case BookingChannel.RESTAURANT:
        return "#ff6600";

      case BookingChannel.TELEPERFORMANCE:
        return "#828282";

      default:
        return "#9933ff";
    }
  }

  private createEventFromAvailableBookingSlot(availableBookingSlots: AvailableSlot[]): EventInput[] {
    const events: EventInput[] = [];

    for (const slot of availableBookingSlots) {
      events.push({
        start: slot.dateTime,
        end: slot.timeSlotsEnd,
        title: this.availableSlotTitle,
        interactive: true,
        color: "#5a7443",
        extendedProps: {
          type: AvailableSlot.name,
          value: availableBookingSlots
        }
      });
    }

    return events;
  }
}