import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AsYouType, isValidNumber } from 'libphonenumber-js';
import { Contact } from '../../../../data/contact';

@Component({
  selector: 'mcdo-booking-contact-info',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: './contact-info.component.html',
  styleUrl: './contact-info.component.css'
})
export class ContactInfoComponent implements OnInit {

  isSubmitted: boolean;

  @Input()
  addressRequired: boolean;

  @Output()
  onContactSubmit = new EventEmitter<Contact>();

  contactForm = this.fb.group({
    firstName: this.fb.control(null, {validators: [Validators.required]}),
    lastName: this.fb.control(null, {validators: [Validators.required]}),
    phone: this.fb.control(null, {validators: [Validators.required, this.phoneNumberValidator]}),
    email: this.fb.control(null, {validators: [Validators.email, Validators.required]}),
    emailConfirm: this.fb.control(null, {validators: [Validators.email, Validators.required]}),
  }, { validators: this.emailConfirmedValidator });
  
  constructor(
    private fb : FormBuilder
  ) {}

  ngOnInit(): void {
    this.contactForm.controls.phone.valueChanges.subscribe(x => {
      this.contactForm.controls.phone.setValue(new AsYouType("CH").input(x), { emitEvent: false });
    })
  }

  private phoneNumberValidator(control: AbstractControl): ValidationErrors {
    if (control.touched) {
      return !isValidNumber(control.value, "CH") ? { "invalidPhoneNumber": control.value } : null;
    }

    return null;
  }

  private emailConfirmedValidator(group: AbstractControl): ValidationErrors {
    let email = group.get("email").value as string;
    let emailConfirm = group.get("emailConfirm").value as string;

    if (email !== emailConfirm) {
      return {
        "emailNotConfirmed": emailConfirm
      }
    }

    return null;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.contactForm.valid) {
      const contactVal = this.contactForm.value;
      this.onContactSubmit.emit(
        {
          firstName: contactVal.firstName,
          lastName: contactVal.lastName,
          phoneNumber: contactVal.phone,
          email: contactVal.email
        }
      );
    }
  }
}
