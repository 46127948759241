<div class="row" style="min-height: 35vh;">
    <div class=" col-xs-12 col-sm-2">
        <div class="mb-3">
            <label class="form-label">{{ "booking-form.store-slot-select.date-input" | translate }}</label>
            <mcdo-input-datepicker [isMobile]="isMobile" [(ngModel)]="dateSelected" (ngModelChange)="onDateChange($event)"></mcdo-input-datepicker>
        </div>

        @if(currentStore) {
            <div class="mb-3">
                <label class="form-label">{{ "global.labels.store" | translate }}</label>
                <div>{{ currentStore?.publicName }}</div>
            </div>
            <ng-container [ngTemplateOutlet]="eventInfoStore" [ngTemplateOutletContext]="{ $implicit: getStoreTooltipContext(currentStore) }"></ng-container>
        }

        @if(currentSlot) {
            <div class="mb-3">
                <label class="form-label">{{ "global.labels.timeSlot" | translate }}</label>
                <div>{{ currentSlot.dateTime | date:"HH:mm" }} - {{ currentSlot.timeSlotsEnd | date:"HH:mm" }}</div>
            </div>
        }

        @if(isMobile && dateSelected) {
            <div class="mb-3">
                <button class="btn btn-primary" (click)="openMapAndSlotModal()">
                    {{ "booking-form.store-slot-select.button." + (currentSlot ? "update-store" : "select-store") | translate }}
                </button>
            </div>
        }

        @if(currentSlot) {
            <div class="mb-3">
                <button class="btn btn-secondary" (click)="onSlotSelected()">{{ "global.button.validate" | translate }}</button>
            </div>
        }
    </div>

    @if(isMobile) {
        <ng-template #mapAndSlotModal let-modal>
            <div class="modal-header">
                <h4 class="modal-title">{{ "booking-form.store-slot-select.modal-title" | translate }}</h4>
                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <ng-container [ngTemplateOutlet]="mapAndSlot"></ng-container>
            </div>
        </ng-template>
    } @else {
        <div class="col-sm-10">
            <ng-container [ngTemplateOutlet]="mapAndSlot"></ng-container>
        </div>
    }

</div>

<!-- Store and slot selection template -->
<ng-template #mapAndSlot>
    @if (dateSelected) {
        <div class="col-12">
            <div class="row">
                <mcdo-maps  [allStores]="stores"
                            [mapOptions]="mapOptions"
                            (onStoreSelected)="currentStore = $event"
                            style="height: 70vh;">
                    <ng-template let-store>
                        <ng-container [ngTemplateOutlet]="storeTooltipTemplate" [ngTemplateOutletContext]="{ $implicit: getStoreTooltipContext(store) }"></ng-container>
                    </ng-template>
                </mcdo-maps>
            </div>
            <div class="row mt-1">
                @if(currentStore) {
                    <h5>{{ 'booking-form.store-slot-select.select-slot' | translate }}</h5>
                    @for (slot of storeSlots; track $index) {
                        <div class="col-auto">
                            <input type="radio" class="btn-check" [(ngModel)]="currentSlot" [value]="slot" [id]="'slot-' + $index">
                            <label class="btn btn-outline-primary" [htmlFor]="'slot-' + $index">{{ slot.dateTime | date:'HH:mm' }} - {{ slot.timeSlotsEnd | date:'HH:mm' }}</label>
                        </div>
                    }
                }
            </div>
        </div>
    } @else {
        <img src="/assets/images/calendar.jpg" style="width: 80%;"/>
    }
</ng-template>